$(function() {
  var $editor = $('#launch_profile_editor')
  var $T = generateTemplatePreviewSetters()

  bindTemplateSelectionButton()
  bindFormSubmission()

  bindImagesInputs()
  bindUserInfoInputs()
  bindColorInputs()

  bindCollapsibleCaretsMovement()

  function bindTemplateSelectionButton() {
    $('.templateButton').click(function() {
      setTemplate($(this).attr('data-template-id'))
    })

    function setTemplate(id) {
      $('#launch_profile_template_id').val(id)
      $('#launch_profile_template_selector').hide()
      $('#launch_profile_template_editor').show()
    }
  enableSubmit()
  }

  function bindFormSubmission() {
    $editor
      .on('change', function() { enableSubmit() })
      .on('submit', function() { disableSubmit() })
      .on('ajax:success', function(ev) {
        if (isAjaxTargetTheForm(ev)) {
          toastSaveAlert();
        }
      })
      .on('ajax:error', function(ev, data) {
        if (isAjaxTargetTheForm(ev)) {
          window.toastr_alert('error', 'Error!', data.responseText)
          enableSubmit()
        }
      })

    function isAjaxTargetTheForm(ev) {
      return ev.target === ev.currentTarget
    }

    function toastSaveAlert() {
      window.toastr_alert('success', 'Success!', 'Launch Profile Saved.')
    }

    function redirectWithErrorMessage(message) {
      message = 'Launch Profile not saved. Please check this image error and try again: <br/>' + message
      window.location = window.location.href.replace('edit', '') + '?error_msg=' + message
    }
  }

  function bindImagesInputs() {
    bindImageInput('#profile-images-container', $T.setImage.profile)
    bindImageInput('#masthead-images-container', $T.setImage.masthead)

    function bindImageInput(container, setImage) {
      var $container = $(container)

      bindActiveImageChange()
      bindAutomaticImagesLoading()

      function bindActiveImageChange() {
        $container.on('change', 'input[type=radio]', function() {
          uncheckAllCheckboxesButOne(this)
          setImage(getActiveImageSrc())
        })
      }

      function uncheckAllCheckboxesButOne(one) {
        $container.find('input[type=radio]').not(one).prop('checked', false)
        $(one).prop('checked', true)
      }

      function bindAutomaticImagesLoading() {
        $container.on('change', 'input[type="file"]', function() {
          var _this = this
          if (_this.files && _this.files[0]) {
            loadImageSrcFromFileInput(_this, function(src) {
              findAssociatedPreviewImage(_this).attr('src', src)
              setImage(getActiveImageSrc())
            })
          }
        })
      }

      function getActiveImageSrc() {
        return findAssociatedPreviewImage(getActiveCheckbox()).attr('src')
      }

      function getActiveCheckbox() {
        return $container.find('input[type=radio]:checked')
      }
    }

    function loadImageSrcFromFileInput(input, onLoad) {
      var reader = new FileReader()
      reader.onload = function(e) { onLoad(e.target.result) }
      reader.readAsDataURL(input.files[0])
    }

    function findAssociatedPreviewImage(el) { return $(el).closest('.client_image').find('img') }
  }

  function bindUserInfoInputs() {
    [1, 2, 3, 4, 5, 6, 7].forEach(function(i) {
      var baseInputId = '#launch_profile_row_' + i + '_'

      $(baseInputId + 'info_text').on('keyup', function() { $T.setRow.info_text(i, $(this).val()) })
      $(baseInputId + 'font_size').on('change', function() { $T.setRow.font_size(i, $(this).val()) })
      $(baseInputId + 'bold').on('change', function() { $T.setRow.bold(i, $(this).is(':checked')) })
      addSpectrumToInput(baseInputId + 'font_color', $T.setRow.font_color.bind(null, i))
    })
  }

  function bindColorInputs() {
    for (var property in $T.setColor) {
      addSpectrumToInput('#launch_profile_' + property, $T.setColor[property])
    }
  }

  function addSpectrumToInput(input, onColorSet, property) {
    var $input = $(input)
    $input.spectrum({
      showInput: true,
      preferredFormat: 'hex6',
      move: function (color) { onColorSet(color) },
      hide: function (color) { onColorSet(color) },
      change: enableSubmit
    })
    removeFloatingLabelForSpectrumInput($input)
  }

  function removeFloatingLabelForSpectrumInput($input) {
    $input.closest('.form-group').find('.control-label').removeClass('control-label')
  }

  function enableSubmit() {
    $('#submit_button').attr('disabled', false)
  }

  function disableSubmit() {
    $('#submit_button').attr('disabled', true)
  }

  function bindCollapsibleCaretsMovement() {
    $editor
      .on('hide.bs.collapse', function(ev) {
        $('[data-target="#' + ev.target.id + '"]')
          .find('.material-icons')
          .css({transition: 'transform 0.5s', transform: 'rotate(0)'})
      })
      .on('show.bs.collapse', function(ev) {
        $('[data-target="#' + ev.target.id + '"]')
          .find('.material-icons')
          .css({transition: 'transform 0.5s', transform: 'rotate(-90deg)'})
      })
  }

  function generateTemplatePreviewSetters() {
    var $preview = $('#preview')

    function setColor(color, target, property) {
      $preview.find(target).css(property || 'color', color.toHexString())
    }

    function $row(n) { return $preview.find('#row_' + n + '_info_text') }

    return {
      setImage: {
        profile: function(src) { $preview.find('#templateAgentHeader tr td:first img').attr('src', src) },
        masthead: function(src) { $preview.find('.headerContent img').attr('src', src) },
      },

      setRow: {
        info_text: function(n, text) { $row(n).text(text) },
        bold: function(n, bold) { $row(n).css('font-weight', bold ? 'bold' : 'normal') },
        font_size: function(n, size) { $row(n).css('font-size', size + 'px') },
        font_color: function(n, color) { setColor(color, $row(n)) },
      },

      setColor: {
        page_background: function(color) { setColor(color, '#backgroundTable', 'background-color') },
        body_border: function(color) { setColor(color, '#templateContainer', 'border-color') },
        pre_header_background: function(color) { setColor(color, '#templatePreheader', 'background-color') },
        agent_info_background: function(color) { setColor(color, '#templateAgentHeader', 'background-color') },
        body_background: function(color) { setColor(color, '.bodyContent', 'background-color') },
        content_container: function(color) { setColor(color, '.contentContainer', 'background-color') },
        pre_header_text: function(color) { setColor(color, '.preheaderContent div') },
        article_title: function(color) { setColor(color, '.bodyContent h4') },
        body_text: function(color) { setColor(color, '.bodyContent div') },
        agent_info_text: function(color) { setColor(color, '#templateAgentHeader, #templateAgentHeader h4') },
        link_text: function(color) { setColor(color, '#preview a') },
      }
    }
  }

  $('body').on('ajax:success', '.destroy-launch-profile-image', function(xhr, data, status){
    $(this).closest('.client_image').remove();
    window.toastr_alert('success', 'Success!', 'Image removed successfully.');
  }).on('ajax:error', function(ev, data) {
    var obj = JSON.parse(data.responseText);
    window.toastr_alert('error', 'Error!', obj.message);
  });

  $('.client_profile_image_submit').click(function(event) {
    event.preventDefault();

    var url = $(this).data('url');
    var type = $(this).data('type');
    var img = $(this).closest('.client_image').find('img');

    uploadedFile = document.getElementById('launch_'+ type +'_image').files[0];
    if(uploadedFile !== undefined) {
      var reader = new FileReader();
      reader.readAsDataURL(uploadedFile);
      reader.onload = function() {
        $.ajax({
          type: 'POST',
          url: url,
          data: { 'launch_profile_image[image_type]': type, 'launch_profile_image[image]': reader.result, 'launch_profile_image[image_file_name]': uploadedFile.name },
          dataType: 'json',
          success: function(data) {
            $('#list-'+ type +'-images').append('<div class="row client_image nested-fields" style="padding-bottom:10px;"><div class="col-xs-3 text-center image-container"><img width="130" src="'+ data.image_url +'" alt="Cbk profile queenstown nz 4 2014 150"></div><div class="col-xs-6 text-center image-container"><span class="radio"><label><input class="radio_buttons" name="launch_profile['+ type +'_image_id]" type="radio" value="'+ data.launch_profile_image_id +'"><span class="circle"></span><span class="check"></span>Active?</label></span></div><div class="col-xs-3"><a class="destroy-launch-profile-image btn btn-fab btn-fab-mini btn-danger" data-type="json" data-remote="true" rel="nofollow" data-method="delete" href="'+ data.url +'"><i class="material-icons">delete</i></a></div></div>');
            // Clean input file
            document.getElementById('launch_'+ type +'_image').value = "";
            // Restore default image
            img.attr('src', img.data('default'));
            window.toastr_alert('success', 'Success!', 'Image saved successfully.');
          },
          error: function(jqXHR, textStatus, errorThrown) {
            var obj = jqXHR.responseJSON;
            window.toastr_alert('error', 'Error!', obj.errors.join('<br>'))
          }
        });
      }
    }
    else {
      window.toastr_alert('error', 'Error!', 'Please select an image');
    }
  });
})
