$(function() {
    this.remove_fields = function(link) {
        $(link).prev("input[type=hidden]").val("1");
        return $(link).closest(".option").hide();
    };
    this.add_fields = function(link, association, content) {
        var new_id, regexp;
        new_id = $('.options-container').children().length;
        regexp = new RegExp("new_" + association, "g");
        $('.options-container').append(content.replace(regexp, new_id));
        return $.material.checkbox('.options-container input[type=checkbox]');
    };
    return $(document).on('change', '#survey_question_question_type', function(e) {
        if ($(this).val() === 'options') {
            return $('#survey-question-options-wrapper').show();
        } else {
            return $('#survey-question-options-wrapper').hide();
        }
    });
});
