$(function() {
    let addon_id, clientNames;
    addon_id = $('#client_addon_addon_id').val();
    clientNames = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: '/clients/search.json?addon_id=' + addon_id + '&search_type=filter&filter=%QUERY',
            wildcard: '%QUERY'
        }
    });
    $('input.typeahead').typeahead({
        hint: true,
        highlight: true,
        minLength: 3
    }, {
        name: 'client-names',
        source: clientNames,
        limit: 24
    });
    this.remove_fields = function(link) {
        $(link).prev("input[type=hidden]").val("1");
        return $(link).closest(".industry-addon").hide();
    };
    return this.add_fields = function(link, association, content) {
        var new_id, regexp;
        new_id = $('.industry-addon-container').children().length;
        regexp = new RegExp("new_" + association, "g");
        $('.industry-addon-container').append(content.replace(regexp, new_id));
        return $.material.init();
    };
});
