$(function() {
    $('#newsletter-banner-affix, #newsletter-article-affix').affix({
        offset: 0
    });
    $('#newsletter-banner-pillbox, #newsletter-banner-pillbox').pillbox();
    $('#calendar-wrapper').on('mouseenter', '.newsletter-launch-link', function(e) {
        var callback, client_id, item_type, newsletter_id, scheduled_item_popover;
        item_type = $(this).data('item-type');
        newsletter_id = $(this).data('newsletter-id');
        client_id = $(this).data('client-id');
        scheduled_item_popover = $(this).next();
        callback = function(response) {
            return scheduled_item_popover.popover({
                content: response,
                title: 'SCHEDULED ' + item_type.toUpperCase() + 'S',
                delay: {
                    show: 500,
                    hide: 100
                },
                html: true
            }).popover('show');
        };
        if (item_type === 'banner') {
            return $.get("/clients/" + client_id + "/banner_categories/newsletter_scheduled_banners/" + newsletter_id, '', callback, '');
        } else {
            return $.get("/articles/newsletter_scheduled_articles/" + newsletter_id, '', callback, '');
        }
    });
    $('#calendar-wrapper').on('mouseleave', '.newsletter-launch-link', function(e) {
        var scheduled_item_popover;
        scheduled_item_popover = $(this).next();
        return scheduled_item_popover.popover('hide');
    });
    return $('#calendar-wrapper').on('click', '.newsletter-launch-link', function(e) {
        var launch_date, newsletter_id, valid_launch;
        e.preventDefault();
        launch_date = $(this).data('launch-date');
        newsletter_id = $(this).data('newsletter-id');
        valid_launch = true;
        $('#newsletter-banner-pillbox ul li, #newsletter-article-pillbox ul li').each(function(index, pill) {
            if ($(pill).data('newsletter-id') === newsletter_id) {
                return valid_launch = false;
            }
        });
        if ($(this).parent().attr('class') !== 'disabled') {
            if (valid_launch) {
                return $('#newsletter-banner-pillbox ul, #newsletter-article-pillbox ul').append('<li class="status-info" data-newsletter-id="' + newsletter_id + '">' + launch_date + '</li>');
            }
        }
    });
});
