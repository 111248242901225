import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["launchYear", "launchMonth"]
  static values = {
    url: String
  }

  connect() {
    this.loadIssues(new Event("loadIssues"))
  }

  loadIssues(event) {
    const csrf_token = document.querySelector("[name='csrf-token']").content
    let issues_wrapper = document.getElementById("newsletter_issue_options")
    let post_data = JSON.stringify({
      launch_year: this.launchYearTarget.value,
      launch_month: this.launchMonthTarget.value
    })

    fetch(
      this.urlValue,
      {
        method: "POST",
        headers: {
          'X-CSRF-Token': csrf_token,
          'Accept': 'application/html',
          'Content-Type': 'application/json'
        },
        body: post_data
      }
    ).then(
      response => response.text()).then(html => issues_wrapper.innerHTML = html)

    // this is some hard-coded crap because, with the 6.x line, it's no longer going to be needed/used/exist
    let loaderElem = document.getElementById('loader')
    loaderElem.classList.remove('show')
    loaderElem.classList.add('hidden')
  }
}
