import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    console.log("Let's Get It Started (Ha)")
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this)
    })
  }

  end(event) {
    let position = parseInt(event.newIndex) + 1;
    let action_url = event.item.dataset.actionUrl;
    let url_data = "position=" + position
    console.log(action_url)
    $.ajax({
      url: action_url,
      type: 'GET',
      data: url_data,
      dataType: 'script'
    });
  }
}
