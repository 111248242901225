import { Controller } from "stimulus"

export default class extends Controller {
  populate(event) {
    let formField = document.getElementById('campaign_segment_ids')
    let initialSegments = formField.value.split(',')
    let segments = initialSegments.filter(tmp => {return tmp !== ""})
    let input = event.target
    let segmentId = input.value
    let index = segments.indexOf(segmentId)
    if (input.checked) {
      segments.push(segmentId)
    } else{
      if (index > -1) {
        segments.splice(index, 1)
      }
    }
    formField.value = segments
  }
}