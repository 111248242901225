import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import ContentLoader from "stimulus-content-loader"
import { Autocomplete} from "stimulus-autocomplete";
// import LaunchedNewsletters from "./launched_newsletters_controller"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
application.register('content-loader', ContentLoader)
application.register('autocomplete', Autocomplete)
// application.register('launched-newsletters', LaunchedNewsletters)
