import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    unselectedClass: String,
    selectedClass: String
  }

  static targets = [ "panels", "output", "trigger", "starts", "button" ]

  configure(event) {
    event.preventDefault()
    let linkElement = event.target.closest("a.campagin-type-link")
    let panelElement = event.target.closest("div.panel")
    let campaignType = linkElement.dataset.campaignType


    this.panelsTargets.forEach((panel) => {
      panel.classList.remove(this.selectedClassValue)
      panel.classList.add(this.unselectedClassValue)
    })

    panelElement.classList.add(this.selectedClassValue)
    this.outputTarget.value = campaignType
    let group_radios = document.getElementById("campaign-segments")

    if(campaignType === 'scheduled') {
      this.buttonTarget.value = 'Build Your Drip Campaign'
      this.triggerTarget.classList.add("hidden")
      this.startsTarget.classList.remove("hidden")
      group_radios.classList.remove("hidden")
    } else {
      this.buttonTarget.value = 'Build Your Journey'
      this.triggerTarget.classList.remove("hidden")
      this.startsTarget.classList.add("hidden")
      group_radios.classList.add("hidden")
    }

    // clunky and stoopid
    let bigWrapper = document.getElementById("campaign-fields")
    bigWrapper.classList.remove("hidden")
  }
}