$(function() {
    $(document).on('click', 'a.turn', function(e) {
        $('#card_' + $(this).attr('id')).toggleClass('flipped');
        e.preventDefault();
    });
    $('.help').tooltip({
        placement: 'right'
    });
    return $('.rejection-tooltip').tooltip({
        container: '.content'
    });
});
