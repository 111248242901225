var toggleLockable;

toggleLockable = function() {
    if ($('#category_is_lockable').is(':checked')) {
        return $('#category_is_locked').removeAttr('disabled');
    } else {
        $('#category_is_locked').attr('disabled', true);
        return $('#category_is_locked').prop('checked', false);
    }
};

$('#category_is_lockable').change(function() {
    return toggleLockable();
});

toggleLockable();