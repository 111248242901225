import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "trigger", "source" ]
  static values = { urlBase: String }

  connect() {
    this.setSearchType()
  }

  setSearchType() {
    const articleSearchTriggers = ['2', 'rewrite_article', 'write_social_media_post'];
    const surveysSearchTriggers = ['3'];
    let triggerValue = this.triggerTarget.value
    let searcher = this.sourceTarget
    let endpoint = ""
    // now set the url endpoint based on the trigger type
    if (articleSearchTriggers.includes(triggerValue)) {
      endpoint = 'articles'
      searcher.removeAttribute('disabled')
    } else if (surveysSearchTriggers.includes(triggerValue)) {
      endpoint = 'surveys'
      searcher.removeAttribute('disabled')
    } else {
      searcher.value = ""
      searcher.setAttribute("disabled", "true")
    }
    this.setSearchPath(endpoint)
  }

  setSearchPath(endpoint) {
    let urlPath = this.urlBaseValue + endpoint
    this.element.setAttribute('data-autocomplete-url-value', urlPath)
  }
}