import consumer from "./consumer"

document.addEventListener('DOMContentLoaded', function() {
  let client_pages = document.getElementById('clients-section')
  if (client_pages) {
    consumer.subscriptions.create("ClientChannel", {
      connected() {
        // Could do something here
      },

      disconnected() {
        // could do someting here
      },

      received(data) {
        if (data.member_id) {
          this.onlineMembers(data)
          console.log('Received data for member ' + data.member_id)
        } else {
          this.addAlert(data)
        }
      },

      onlineMembers(data) {
        let modalIconAccess = document.getElementById('online-members')
        let listItems = document.getElementById('online-members-list')
        let listItem = document.getElementById('member_' + data.member_id)
        let onlineMembersCount = document.getElementById('online-members-count')

        if (data.online === true) {
          listItems.insertAdjacentHTML('afterbegin', data.html)
          onlineMembersCount.innerHTML = String(listItems.children.length)
          modalIconAccess.classList.remove('hidden')
        } else {
          listItem.remove()
          onlineMembersCount.innerHTML = String(listItems.children.length)
          if (listItems.children.length === 0) {
            modalIconAccess.classList.add('hidden')
          }
        }
      },

      addAlert(data) {
        const messages = document.getElementById('flash-messages')
        let flashType = 'alert-success'
        if (data.title.includes('Error')) {
          flashType = 'alert-danger'
        }
        messages.insertAdjacentHTML('beforeend', `<div class='alert alert-dismissable ${flashType}' style="color: #ffffff"><button class='close' data-dismiss='alert'><span style='color: #666666'>x</span></button>${data.body}</div>`)
      }
    })
  }
})