import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
    connect() {
        this.sortable = Sortable.create(this.element, {
            onEnd: this.end.bind(this)
        })
    }

    end(event) {
        let position = parseInt(event.newIndex) + 1;
        let id = event.item.dataset.id;
        $.ajax({
            url: this.data.get("url"),
            type: 'POST',
            data: "position=" + position + "&link_id=" + id,
            dataType: 'script'
        });
    }
}
