import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["keywordId", "keywordIds", "keywordName", "articleId"]
  static values = {url: String}

  create(_event) {
    const csrf_token = document.querySelector("[name='csrf-token']").content
    let post_data = JSON.stringify({
      keyword: {
        id: this.keywordIdTarget.value,
        name: this.keywordNameTarget.value
      }
    })
    let keywords_div = document.getElementById("article_keywords");
    let keyword_ids = this.keywordIdsTarget;

    // if we have a URL (existing keyword) ...
    if (this.urlValue !== "") {
      fetch(
        this.urlValue + '.html',
        {
          method: "POST",
          headers: {
            'X-CSRF-Token': csrf_token,
            'Accept': 'application/html',
            'Content-Type': 'application/json'
          },
          body: post_data
        }
      ).then(
        response => response.text()).then(html => keywords_div.innerHTML = html);
    }
    // if no URL, it's a new article and we need to store the id in the keyword_ids input for article creation and output the badge
    else {
      fetch(
        "/keywords.html",
        {
          method: "POST",
          headers: {
            'X-CSRF-Token': csrf_token,
            'Accept': 'application/html',
            'Content-Type': 'application/json'
          },
          body: post_data,
          credentials: 'same-origin'
        }
      ).then(function (response) {
          return response.text()
        }
      )
        .then(function (html) {
            let parser = new DOMParser();
            let doc = parser.parseFromString(html, "text/html");
            let span = doc.querySelector('span');
            let keyword_id = doc.getElementsByClassName('newly_added_keyword_id')[0];

            keywords_div.append(span);

            if (keyword_ids.value === '') {
              keyword_ids.value = keyword_id.value;
            } else {
              keyword_ids.value += ',' + keyword_id.value;
            }
          }
        );
    }
    // clear out the form field
    this.keywordNameTarget.value = '';

    // this is some hard-coded crap because, with the 6.x line, it's no longer going to be needed/used/exist
    let loaderElem = document.getElementById('loader')
    loaderElem.classList.remove('show')
    loaderElem.classList.add('hidden')
  }
}
