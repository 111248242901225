$(function() {
    return $('.day_of_week_select').change(function(e) {
        return $.ajax({
            url: '/clients/' + $('#launch_schedule_client_id').val() + '/launch_schedule/next_available_launch_date/' + $(this).val(),
            type: 'GET',
            dataType: 'script',
            data: {}
        });
    });
});
