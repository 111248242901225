$(document).ready(function () {
    // Move modal to body
    // Fix Bootstrap backdrop issue with animation.css
    $('.modal').appendTo("body");
    $("[data-toggle=popover]").popover();

    let loaderElem = document.getElementById('loader')
    loaderElem.classList.remove('show')
    loaderElem.classList.add('hidden')

    $(document).ajaxStart(function(ev) {
        let loaderElem = document.getElementById('loader')
        loaderElem.classList.remove('hidden')
        loaderElem.classList.add('show')
    });

    $(document).ajaxComplete(function(ev) {
        let loaderElem = document.getElementById('loader')
        loaderElem.classList.remove('show')
        loaderElem.classList.add('hidden')
    });

    $.material.init();
    $(document.body).on('cocoon:after-insert', function () { $.material.init() });

    (function () {
        window.loadedSmartListing = function(parent) {
            fadeLoadedSmartListing($(parent || document.body).find('.smart-listing'))
        }
        loadedSmartListing()

        $(document.body).on('ajax:complete', function(ev) { fadeLoadedSmartListing(ev.target) })

        function fadeLoadedSmartListing(smartListings) {
            smartListings = $(smartListings)
            smartListings.each(function(_, sl) {
                sl = $(sl).smart_listing()
                if(sl.fadeLoaded) sl.fadeLoaded()
            })
        }
    })();
});
