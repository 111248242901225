$(function() {
    $('.mailing-live-preview').click(function(e) {
        var $this, client_id, mailing_body, mailing_from, mailing_subject, posting, url;
        e.preventDefault();
        $this = $(this);
        client_id = $this.data('client-id');
        mailing_subject = $this.data('mailing-subject');
        mailing_from = $this.data('mailing-from');
        mailing_body = $this.data('mailing-body');
        url = '/clients/' + client_id + '/mailings/live_preview';
        return posting = $.ajax({
            type: "POST",
            url: url,
            dataType: "json",
            data: {
                mailing: {
                    client_id: client_id,
                    mailing_type: 'schedule',
                    status: 'draft',
                    subject: mailing_subject,
                    from: mailing_from,
                    body: mailing_body
                }
            },
            success: function(data) {
                $('#live-preview-content').html(data.preview);
                return $('#live-preview-modal').modal('show');
            },
            error: function(data) {
                return window.toastr_alert('error', 'Error!', 'Live preview was not able to be rendered.');
            }
        });
    });
    $('.article-live-preview').click(function(e) {
        var $this, article_id, client_id, posting, url;
        e.preventDefault();
        $this = $(this);
        client_id = $this.data('client-id');
        article_id = $this.data('article-id');
        url = '/clients/' + client_id + '/articles/' + article_id + '/modal_preview.js';
        return posting = $.ajax({
            type: "GET",
            url: url,
            error: function(data) {
                return window.toastr_alert('error', 'Error!', 'Live preview was not able to be rendered.');
            }
        });
    });
    return $('.js-copy').on('click', function(e) {
        var copy;
        copy = $('#' + $(this).data('sibling')).val();
        $('<input>').val(copy).appendTo('body').select();
        document.execCommand('copy');
        window.toastr_alert('success', 'Success!', 'Copied "' + copy + '" to the clipboard');
    });
});
