// app/javascript/controllers/autosave_controller.js
import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    this.save = this.save.bind(this)
  }

  connect() {
    this.timer = setInterval(this.save, 90000) // Auto save every 90 seconds
  }

  disconnect() {
    clearInterval(this.timer)
  }

  save() {
    const submitBtn = document.getElementById("save-progress-btn")
    submitBtn.click()
  }
}
