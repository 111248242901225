import {Controller} from "stimulus"
// import Rails from "jquery-ujs/src/rails"

export default class extends Controller {
  static targets = ["trigger", "source"]
  static values = {urlBase: String}

  connect() {

  }

  all(event) {
    event.preventDefault();

    let search_form = document.getElementById('search_form')
    let select_element = document.getElementById('member_search_form_select_entire_list')
    let selected_placeholder_checkbox = document.getElementById('selected_placeholder')
    let select_all = select_element.value;

    if (select_all === 'true') {
      select_element.value = 'false';
      selected_placeholder_checkbox.checked = true;
    } else {
      select_element.value = 'true';
      selected_placeholder_checkbox.checked = false;
    }
    search_form.dispatchEvent(new Event('submit', {bubbles: true}))
  }
}
