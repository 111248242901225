import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
    connect() {
        this.sortable = Sortable.create(this.element, {
            onEnd: this.end.bind(this)
        })
    }

    end(event) {
        let id = event.item.dataset.id;
        let parent = event.item.parent;
        let parent_id = '0';
        let next_id = event.item.nextElementSibling.dataset.id;
        let prev_id = event.item.previousElementSibling.dataset.id;
        if(typeof parent !== 'undefined') {
            parent_id = parent.parent.dataset.id;
        }
        let params = { id: id, parent_id: parent_id, prev_id: prev_id, next_id: next_id };

        $.ajax({
            url: this.data.get("url"),
            type: 'POST',
            data: params,
            dataType: 'script'
        });
    }
}
