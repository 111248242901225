$(function() {
    $('#addon-item-form').submit(function(e) {
        e.preventDefault();
        return $.post($(this).attr('action'), $(this).serialize(), (function(data) {
            $('#addon-item-empty-row').remove();
            $('#addon-item-tbody').append('<tr>' + '<td width="90%">' + '<p><a href="/addons/' + data.addon_id + '/addon_items/' + data.id + '" target="_blank">' + data.name + '</a></p>' + '<p>This is a ' + data.item_type + ' that a client ' + data.action + '</p></td>' + '<td width="10%" style="vertical-align: middle; text-align: center">' + '<a data-confirm="Are you sure you want to remove this addon item?" ' + 'class="btn btn-xs btn-danger remove-addon-item-button" rel="nofollow" href="/addons/' + data.addon_id + '/addon_items/' + data.id + '">' + '<i class="fa fa-trash-o"></i></a>' + '</td></tr>');
            $('#addon-item-form')[0].reset();
            return window.toastr_alert('success', 'Success!', 'Addon Item added. ');
        }), 'json').fail(function(data) {
            return window.toastr_alert('error', 'Error!', 'Unable to add the addon item. Reload the page and try again. ');
        });
    });
    $('#survey-question-form').submit(function(e) {
        e.preventDefault();
        if (!$('#survey_question_question').val()) {
            window.toastr_alert('error', 'Error!', "The survey question can't be empty");
            setTimeout((function() {
                return $('#survey-question-form input:submit').attr('disabled', false);
            }), 500);
            return;
        }
        return $.post($(this).attr('action'), $(this).serialize(), (function(data) {
            $('#survey-questions-empty-row').remove();
            $('#survey-questions-tbody').append('<tr>' + '<td width="90%"><p>' + data.question + '</p>' + '<p>Reload the page to edit (or just delete and recreate since it is new!)</p></td>' + '<td width="10%" style="vertical-align: middle; text-align: center"><a data-confirm="Are you sure you want to remove this survey question?" ' + 'class="btn btn-xs btn-danger remove-survey-question-button" rel="nofollow" href="/addons/' + data.addon_id + '/surveys/' + data.survey_id + '/survey_questions/' + data.id + '">' + '<i class="fa fa-trash-o"></i></a></td></tr>');
            $('#survey-question-form')[0].reset();
            $('.options-container').empty();
            $('#survey-question-options-wrapper').css('display', 'none');
            return window.toastr_alert('success', 'Success!', 'Survey question added. ');
        }), 'json').fail(function(data) {
            return window.toastr_alert('error', 'Error!', 'Unable to add the survey question. Reload the page and try again. ');
        });
    });
    $('#industry-addon-form').submit(function(e) {
        var action_url;
        e.preventDefault();
        action_url = '/industries/' + $('#industry_addon_industry_id').val() + '/industry_addons/';
        return $.post(action_url, $(this).serialize(), (function(data) {
            var free_access;
            free_access = 'No';
            if (data.allows_unpaid_access) {
                free_access = 'Yes';
            }
            $('#industry-addon-empty-row').remove();
            $('#industry-addon-tbody').append('<tr>' + '<td width="90%">' + '<p><a href="/industries/' + data.industry_id + '/industry_addons/' + data.id + '" target="_blank">' + data.industry_name + '</a></p>' + '<p>Free access? ' + free_access + '<br/>' + 'Allows ' + data.unpaid_access_limit + ' leads per month</p></td>' + '<td width="10%" style="vertical-align: middle; text-align: center">' + '<a data-confirm="Are you sure you want to remove access for this industry?" class="btn btn-xs btn-danger remove-assignee-button" ' + 'rel="nofollow" href="/industries/' + data.industry_id + '/industry_addons/' + data.id + '">' + '<i class="fa fa-trash-o"></i></a></td></tr>');
            $('#industry-addon-form')[0].reset();
            return window.toastr_alert('success', 'Success!', 'Assignee added. ');
        }), 'json').fail(function(data) {
            return window.toastr_alert('error', 'Error!', 'Unable to add the assignee. Reload the page and try again. ');
        });
    });
    $('#client-addon-form').submit(function(e) {
        var action_url, client_id, post_data, raw_client_id;
        e.preventDefault();
        raw_client_id = $('#client_addon_client_id').val();
        client_id = raw_client_id.substr(raw_client_id.indexOf('^') + 1);
        action_url = '/clients/' + client_id + '/client_addons/';
        post_data = {
            client_addon: {
                addon_id: $('#client_addon_addon_id').val(),
                domain: $('#client_addon_domain').val(),
                partner_id: $('#client_addon_partner_id').val(),
                is_blocked: $('#client_addon_is_blocked').val(),
                client_id: client_id
            }
        };
        return $.post(action_url, post_data, (function(data) {
            var blocked_text, restricted_domain;
            blocked_text = '';
            if (data.is_blocked === true) {
                blocked_text = '<p class="text-danger">BLOCKING ALL INTERACTIVE CONTENT</p>';
            }
            restricted_domain = 'Yes';
            if (data.domain === '') {
                restricted_domain = 'No';
            }
            $('#client-addon-empty-row').remove();
            $('#client-addon-tbody').append('<tr>' + '<td width="90%"><p><a href="/clients/' + data.client_id + '/client_addons/' + data.id + '" target="_blank">' + data.client_name + '</a></p>' + '<p><strong>Domain restriction on?</strong> ' + restricted_domain + '<br/><strong>Purchased from</strong> ' + data.purchased_from + '</p>' + blocked_text + '</td>' + '<td width="10%" style="vertical-align: middle; text-align: center"><a data-confirm="Are you sure you want to remove access for this client?" ' + 'class="btn btn-xs btn-danger remove-assignee-button" rel="nofollow" href="/clients/' + data.client_id + '/client_addons/' + data.id + '"><i class="fa fa-trash-o"></i></a></td></tr>');
            $('#client-addon-form')[0].reset();
            $('#client-addon-form input:submit').attr('disabled', false);
            return window.toastr_alert('success', 'Success!', 'Assignee added. ');
        }), 'json').fail(function(data) {
            return window.toastr_alert('error', 'Error!', 'Unable to add the assignee. Reload the page and try again. ');
        });
    });
    $(document).on('click', '.remove-assignee-button, .remove-addon-item-button, .remove-survey-question-button', function(e) {
        var element;
        e.preventDefault();
        element = $(this);
        return $.ajax({
            url: element.attr('href'),
            dataType: 'json',
            method: 'DELETE'
        }).done(function(data) {
            element.parents('tr').remove();
            return window.toastr_alert('success', 'Success!', 'Item removed. ');
        }).fail(function(data) {
            return window.toastr_alert('error', 'Error!', 'Unable to remove the item. Reload the page and try again. ');
        });
    });
    this.remove_fields = function(link) {
        $(link).prev("input[type=hidden]").val("1");
        return $(link).closest(".option").hide();
    };
    this.add_fields = function(link, association, content) {
        var new_id, regexp;
        new_id = $('.options-container').children().length;
        regexp = new RegExp("new_" + association, "g");
        return $('.options-container').append(content.replace(regexp, new_id));
    };
    return $(document).on('change', '#survey_question_question_type', function(e) {
        if ($(this).val() === 'options') {
            return $('#survey-question-options-wrapper').show();
        } else {
            return $('#survey-question-options-wrapper').hide();
        }
    });
});
