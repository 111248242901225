import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["library"]

    load(event) {
        let options_select = event.target;
        let url = options_select.dataset.url + '?library=' + this.libraryTarget.value;
        let category_select = document.getElementById("newsletters_articles_filter_form_category_id");
        fetch(url).then(response => response.text()).then(html => category_select.innerHTML = html);
    }
}
