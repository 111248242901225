var disable_buttons, enable_buttons, live_validation, validate;

validate = function() {
    var body, err_msg, from, name, subject;
    name = $('#mailing_name').val();
    from = $('#mailing_from').val();
    subject = $('#mailing_subject').val();
    body = CKEDITOR.instances.mailing_body.getData();
    err_msg = '';
    if (name === '') {
        err_msg += 'The name field is required. ';
        disable_buttons();
    } else {
        enable_buttons();
    }
    if (from === '') {
        err_msg += 'The from field is required. ';
        disable_buttons();
    } else {
        enable_buttons();
    }
    if (subject === '') {
        err_msg += 'The subject field is required. ';
        disable_buttons();
    } else {
        enable_buttons();
    }
    if (body === '') {
        err_msg += 'The body is required. ';
        disable_buttons();
    } else {
        enable_buttons();
    }
    if (err_msg !== '') {
        window.toastr_alert('error', 'Error!', 'You must completed the required fields (name, from, subject and body) to save an OnTarget mailing. The following fields were incomplete: ' + err_msg);
        return false;
    } else {
        return true;
    }
};

disable_buttons = function() {
    $('#main-submit-btn').prop('disabled', true);
    $('#save-progress-btn').prop('disabled', true);
    $('#live-preview-btn').prop('disabled', true);
    return $('#send-preview-modal-btn').prop('disabled', true);
};

enable_buttons = function() {
    $('#main-submit-btn').removeAttr('disabled');
    $('#save-progress-btn').removeAttr('disabled');
    return $('#live-preview-btn').removeAttr('disabled');
};

live_validation = function() {
    var body, from, name, subject;
    name = $('#mailing_name').val();
    from = $('#mailing_from').val();
    subject = $('#mailing_subject').val();
    body = CKEDITOR.instances.mailing_body.getData();
    if (name !== '' && from !== '' && subject !== '' && body !== '') {
        return enable_buttons();
    } else {
        return disable_buttons();
    }
};

$(function() {
    if ($('#mailing_id').val() === '') {
        disable_buttons();
    }
    $('input[name="deliver_to"]').on('change', function() {
        var value;
        value = $('input[name="deliver_to"]:checked').val();
        if (value === '1') {
            return alert('This mailing will go to all subscribed members');
        } else {
            return alert('This mailing will go to a segment, or group, of your subscribed members');
        }
    });
    $('[name="mailing[mailing_type]"]').on('change', function() {
        var map, value;
        value = $('[name="mailing[mailing_type]"]:checked').val();
        map = {
            'rough-draft': ['draft', "Draft OnTarget mailings are just that ... drafts. You can edit and tweak to your heart's content and they will never be delivered (until you're ready)."],
            'schedule': ['scheduled', 'Schedule OnTarget mailings to go out on the day and time you pick from the form field below. When scheduling an OnTarget mailing, you must provide a launch date/time to save. If you do not yet know or have a set launch time, leave the status to "Draft".'],
            'on-demand': ['sent', 'On-Demand OnTarget mailings are DELIVERED IMMEDIATELY after you save them. DO NOT CHOOSE THIS TYPE unless you are 100% certain you want it to go now. There is no stopping this once you submit the form.']
        };
        $('#mailing_status').val(map[value][0]);
        $('#scheduled-hint').html(map[value][1]);
        if (value === 'rough-draft') {
            $('#main-submit-btn').attr('data-target', '#');
            $('#main-submit-btn').prop('disabled', true).html('Schedule or Send');
            $('#save-progress-btn').html('Save Draft');
        }
        if (value === 'schedule') {
            $('#save-progress-btn').html('Save Progress');
            $('#scheduled-delivery-modal').modal('show');
            if ($('#scheduled_at_temp').val() === '') {
                $('#main-submit-btn').prop('disabled', false).html('Schedule');
                $('#main-submit-btn').removeClass('btn-danger').addClass('btn-primary');
                $('#main-submit-btn').attr('data-target', '#scheduled-delivery-modal');
            } else {
                $('#main-submit-btn').prop('disabled', false).html('Unschedule');
                $('#main-submit-btn').removeClass('btn-primary').addClass('btn-danger');
                $('#main-submit-btn').attr('data-target', '#unschedule-modal');
            }
        } else if (value === 'on-demand') {
            $('#main-submit-btn').attr('data-target', '#immediate-delivery-modal');
            $('#main-submit-btn').removeClass('btn-danger').addClass('btn-primary');
            $('#main-submit-btn').prop('disabled', false).html('Send');
            $('#save-progress-btn').html('Save Progress');
        }
    });
    $('.mailing_form').submit(function(e) {
        var checkedValues, valid;
        valid = validate();
        checkedValues = $('input:checkbox:checked').map(function() {
            return this.value;
        }).get();
        $('#mailing_segment_ids').val(checkedValues);
        if (!valid) {
            e.preventDefault();
            return false;
        }
        if ($('#scheduled_at_temp').val() !== '') {
            $('#mailing_scheduled_at').attr('value', $('#scheduled_at_temp').val());
        }
        if ($('.mailing_mailing_type input:checked').val() === 'on-demand') {
            if (confirm('Once again, by selecting to launch this email right now, please understand it will SEND IMMEDIATELY!')) {

            } else {
                return e.preventDefault();
            }
        }
    });
    $('.mailing_form input.required').on('blur', function() {
        return live_validation();
    });
    $('#immediate-confirm-btn, #schedule-delivery-btn').click(function(e) {
        var checkedValues, mailing_status, mailing_type, url;
        url = '/clients/' + $('#mailing_client_id').val() + '/mailings/save';
        if ($('[name="mailing[mailing_type]"]:checked').val() === 'on-demand') {
            mailing_type = 'on-demand';
            mailing_status = 'scheduled';
        } else if ($('[name="mailing[mailing_type]"]:checked').val() !== '') {
            mailing_type = $('[name="mailing[mailing_type]"]:checked').val();
            mailing_status = $('#mailing_status').val();
        } else {
            mailing_type = 'schedule';
            mailing_status = 'draft';
        }
        checkedValues = $('input:checkbox:checked').map(function() {
            return this.value;
        }).get();
        $('#mailing_scheduled_at').attr('value', $('#scheduled_at_temp').val());
        $('#scheduled-at').removeClass('hidden').html($('#mailing_scheduled_at').val());
        return $.ajax({
            type: "POST",
            url: url,
            dataType: "script",
            data: {
                mailing_id: $('#mailing_id').val(),
                mailing: {
                    client_id: $('#mailing_client_id').val(),
                    status: mailing_status,
                    name: $('#mailing_name').val(),
                    from: $('#mailing_from').val(),
                    subject: $('#mailing_subject').val(),
                    body: CKEDITOR.instances.mailing_body.getData(),
                    preview_recipients: $('#mailing_preview_recipients').val(),
                    mailing_type: mailing_type,
                    scheduled_at: $('#mailing_scheduled_at').val(),
                    segment_ids: checkedValues,
                    deliver_to: $("input[name='deliver_to']:checked").val(),
                    previous_scheduled_at: $("#previous_scheduled_at").val()
                }
            },
            success: function(data) {
                $('#scheduled-delivery-modal, #immediate-delivery-modal').modal('hide');
                return window.location.replace('/clients/' + $('#mailing_client_id').val() + '/mailings');
            }
        });
    });
    $('#unschedule-confirm-btn').click(function(e) {
        var checkedValues, mailing_status, mailing_type, url;
        $('#mailing_scheduled_at').attr('value', $('#scheduled_at_temp').val());
        $('#scheduled-at').addClass('hidden').html('');
        $('#main-submit-btn').prop('disabled', false).html('Schedule');
        $('#main-submit-btn').attr('data-target', '#scheduled-delivery-modal');
        mailing_type = 'schedule';
        mailing_status = 'draft';
        url = '/clients/' + $('#mailing_client_id').val() + '/mailings/save';
        checkedValues = $('input:checkbox:checked').map(function() {
            return this.value;
        }).get();
        return $.ajax({
            type: "POST",
            url: url,
            dataType: "script",
            data: {
                mailing_id: $('#mailing_id').val(),
                mailing: {
                    client_id: $('#mailing_client_id').val(),
                    status: mailing_status,
                    name: $('#mailing_name').val(),
                    from: $('#mailing_from').val(),
                    subject: $('#mailing_subject').val(),
                    body: CKEDITOR.instances.mailing_body.getData(),
                    preview_recipients: $('#mailing_preview_recipients').val(),
                    mailing_type: mailing_type,
                    scheduled_at: '',
                    segment_ids: checkedValues,
                    deliver_to: $("input[name='deliver_to']:checked").val(),
                    previous_scheduled_at: $("#previous_scheduled_at").val()
                }
            }
        });
    });
    $('#save-progress-btn').click(function(e) {
        var checkedValues, mailing_status, mailing_type, url, valid;
        valid = validate();
        if (!valid) {
            e.preventDefault();
            return false;
        }
        url = '/clients/' + $('#mailing_client_id').val() + '/mailings/save';
        if ($('[name="mailing[mailing_type]"]:checked').val() === 'on-demand') {
            mailing_type = 'schedule';
            mailing_status = 'draft';
        } else if ($('[name="mailing[mailing_type]"]:checked').val() === 'schedule') {
            mailing_type = 'schedule';
            mailing_status = 'scheduled';
        } else if ($('[name="mailing[mailing_type]"]:checked').val() !== '') {
            mailing_type = $('[name="mailing[mailing_type]"]:checked').val();
            mailing_status = $('#mailing_status').val();
        } else {
            mailing_type = 'schedule';
            mailing_status = 'draft';
        }
        checkedValues = $('input:checkbox:checked').map(function() {
            return this.value;
        }).get();
        if ($('#scheduled_at_temp').val() !== '') {
            $('#mailing_scheduled_at').val($('#scheduled_at_temp').val());
            $('#scheduled-at').removeClass('hidden').html($('#mailing_scheduled_at').val());
        }
        return $.ajax({
            type: "POST",
            url: url,
            dataType: "script",
            data: {
                mailing_id: $('#mailing_id').val(),
                mailing: {
                    client_id: $('#mailing_client_id').val(),
                    status: mailing_status,
                    name: $('#mailing_name').val(),
                    from: $('#mailing_from').val(),
                    subject: $('#mailing_subject').val(),
                    body: CKEDITOR.instances.mailing_body.getData(),
                    preview_recipients: $('#mailing_preview_recipients').val(),
                    mailing_type: mailing_type,
                    scheduled_at: $('#mailing_scheduled_at').val(),
                    segment_ids: checkedValues,
                    deliver_to: $("input[name='deliver_to']:checked").val(),
                    previous_scheduled_at: $("#previous_scheduled_at").val()
                }
            },
            success: function(data) {
                $('#live-preview-btn').removeAttr('disabled');
                return $('#send-preview-modal-btn').removeAttr('disabled');
            }
        });
    });
    $('#mailing-preview-btn').click(function(e) {
        var posting, url;
        if (confirm('By selecting to preview this OnTarget mailing now, all noted recipients will receive a preview email within a few minutes.')) {
            url = '/clients/' + $('#mailing_client_id').val() + '/mailings/send_preview';
            return posting = $.ajax({
                type: "POST",
                url: url,
                dataType: "script",
                data: {
                    mailing: {
                        client_id: $('#mailing_client_id').val(),
                        mailing_id: $('#mailing_id').val(),
                        mailing_type: 'schedule',
                        preview_recipients: $('#mailing_preview_recipients').val()
                    }
                },
                success: function(data) {
                    window.toastr_alert('success', 'Success!', 'Preview sent.');
                    return $('.send-preview-btn').attr('disabled', false);
                },
                error: function(data) {
                    return window.toastr_alert('error', 'Error!', 'Preview was not able to be sent.');
                }
            });
        } else {
            return e.preventDefault();
        }
    });
    $('#live-preview-btn').click(function(e) {
        var url;
        e.preventDefault();
        url = '/clients/' + $('#mailing_client_id').val() + '/mailings/live_preview';
        return $.ajax({
            type: "POST",
            url: url,
            dataType: "script",
            data: {
                mailing: {
                    client_id: $('#mailing_client_id').val(),
                    mailing_id: $('#mailing_id').val(),
                    mailing_type: 'preview',
                    subject: $('#mailing_subject').val(),
                    from: $('#mailing_from').val(),
                    body: CKEDITOR.instances.mailing_body.getData(),
                    preview_recipients: $('#mailing_preview_recipients').val()
                }
            }
        });
    });
    $('[data-toggle="tooltip"]').tooltip();
    let today = new Date();
    $('#scheduled_at_temp').datetimepicker({
        icons: {
            time: 'fa fa-clock-o',
            date: 'fa fa-calendar',
            up: 'fa fa-chevron-up',
            down: 'fa fa-chevron-down',
            previous: 'fa fa-chevron-left',
            next: 'fa fa-chevron-right',
            today: 'fa fa-screenshot',
            clear: 'fa fa-trash'
        },
        minDate: today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate(),
        stepping: 30,
        format: "YYYY-MM-DD hh:mm A",
        toolbarPlacement: 'top',
        showClear: true
    });
    $('.deliver-to-radio').click(function(e) {
        var btn_selected;
        btn_selected = $('input[name=deliver_to]:checked').val();
        if (btn_selected === '1') {
            return $('input[type=checkbox]').prop('checked', false);
        }
    });
    $('.segment-check-box').click(function(e) {
        if ($(this).prop('checked')) {
            return $('#deliver_to_2').prop('checked', true);
        }
    });
    $('.send-preview-modal-btn').click(function(e) {
        var id;
        id = $(this).data('mailing');
        return $('#mailing_id').val(id);
    });
    $('#mailing_body').on('key', function() {
        return live_validation();
    });

    var ed = CKEDITOR.instances['mailing_body'];
    if(typeof ed !== 'undefined'){
        ed.on('blur', function(e) {
            return live_validation();
        });
    }
});
