import {Controller} from "stimulus"
// import Rails from "jquery-ujs/src/rails"

export default class extends Controller {
  static targets = ["checkbox"]
  static values = { url: String }

  connect() {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.addEventListener('change', this.handleCheckboxChange.bind(this))
    })
  }

  handleCheckboxChange(event) {
    let member_ids = []

    this.checkboxTargets.filter(checkbox => checkbox.checked).forEach(checkbox => {
      member_ids.push(parseInt(checkbox.value))
    })

    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'text/javascript',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ member_ids: member_ids })
    })
      .then(response => response.text())
      .then(script => eval(script))
      .then(data => {
        console.log('Success:', data)
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }
}