var alert_stats_export, filter_member_export, load_tags, save_as_segment;

// $.fn.smart_listing_controls.filter = function(filter) {
//   var field, form;
//   form = filter.closest("form");
//   field = form.find('input#filter.search');
//   return $.fn.smart_listing.observeField(field, {
//     onChange: function() {
//       return 'disabled';
//     }
//   });
// };

window.clearSearchField = function(key) {
  var elem_id, filter_elem_id, second_elem_id;
  elem_id = '#member_search_form_' + key;
  filter_elem_id = '#filter-' + key;
  second_elem_id = '#member_search_form_' + key + '_second';
  if (key.match(/tags_/)) {
    $(elem_id).removeAttr('checked');
    $(second_elem_id).removeAttr('checked');
  } else {
    $(elem_id).val('');
    $(second_elem_id).val('');
  }
  $(filter_elem_id).remove();
};

filter_member_export = function(e) {
  var data, excluded, tags;
  e.preventDefault();
  data = {};
  data['send_to'] = $('#send_to').val();
  data['export_stats'] = $('#export_stats').is(':checked');
  data['export_engagements'] = $('#export_engagements').is(':checked');
  data['moxie_format'] = $('#moxie_format').is(':checked');
  $('#search_form').serializeArray().forEach(function(input) {
    return data[input.name] = input.value;
  });
  tags = [];
  excluded = [];
  $('.member_search_form_tags').find('input[type="checkbox"]').each(function(tag) {
    if (this.checked) {
      return tags.push(this.value);
    }
  });
  $('.member_search_form_tags_excluded').find('input[type="checkbox"]').each(function(tag) {
    if (this.checked) {
      return excluded.push(this.value);
    }
  });
  data['member_search_form[tags][]'] = tags;
  data['member_search_form[tags_excluded][]'] = excluded;
  return $.ajax({
    url: '/clients/' + data['member_search_form[client_id]'] + '/members/export',
    type: 'POST',
    data: data,
    dataType: 'json',
    // success: toastr_alert('success', 'Success!', 'List has been queued for export and will be emailed to you when complete.', 7)
  });
};

save_as_segment = function(element) {
  var data;
  data = {};
  $('#search_form').serializeArray().forEach(function(input) {
    return data[input.name] = input.value;
  });
  data['segment_name'] = $('#segment_name').val();
  data['member_search_form[tags][]'] = [];
  $("input[name='member_search_form[tags][]']:checked").map(function() {
    data['member_search_form[tags][]'].push(this.value);
  });
  data['member_search_form[tags_excluded][]'] = [];
  $("input[name='member_search_form[tags_excluded][]']:checked").map(function() {
    data['member_search_form[tags_excluded][]'].push(this.value);
  });
  if ($('#member_search_form_status').val() !== 'unsubscribed') {
    if ($('#segment_name').val()) {
      return $.ajax({
        url: '/clients/' + data['member_search_form[client_id]'] + '/segments',
        type: 'POST',
        data: data,
        dataType: 'script'
      });
    } else {
      return window.toastr_alert('error', 'Warning', 'You must provide a name for this segment', 10);
    }
  } else {
    return window.toastr_alert('error', 'Warning', 'Cannot save segment with no subscribed members', 15);
  }
};

alert_stats_export = function(element) {
  console.log(element);
  if ($(element).is(':checked')) {
    return window.toastr_alert('error', 'Warning', 'Selecting this option can significantly increase the amount of time it takes to assemble your export', 10);
  }
};

window.remove_custom_field = function(field_id) {
  $("#custom_fields").find("option[value='']").attr('selected', true);
  $("#custom_fields").find("option[value='" + field_id + "']").removeAttr('disabled');
  $("#custom-field-row-" + field_id).remove();
  return false;
};

load_tags = function() {
  var add_filter_field, cust_field_chars, element_string, field_id, field_value, filter_field, filter_name, key, non_tag_chars, tag_chars, tag_excluded_chars;
  field_value = $(this).val();
  field_id = $(this).attr('id');
  non_tag_chars = 19;
  tag_chars = 24;
  tag_excluded_chars = 33;
  cust_field_chars = 33;
  add_filter_field = true;
  if (field_value !== '') {
    if (field_id.match(/member_search_form_tags_excluded_/)) {
      field_value = $("label[for='" + field_id + "']").text();
      key = 'tags_excluded_' + field_id.substring(tag_excluded_chars, field_id.length);
      filter_field = 'filter-' + key;
      filter_name = 'excluded tag';
      $("#" + filter_field).remove();
      if (!$(this).is(':checked')) {
        add_filter_field = false;
      }
    } else if (field_id.match(/member_search_form_tags_style/)) {
      return;
    } else if (field_id.match(/member_search_form_tags_/)) {
      field_value = $("label[for='" + field_id + "']").text();
      key = 'tags_' + field_id.substring(tag_chars, field_id.length);
      filter_field = 'filter-' + key;
      filter_name = 'tag';
      $("#" + filter_field).remove();
      if (!$(this).is(':checked')) {
        add_filter_field = false;
      }
    } else if (field_id.match(/member_search_form_custom_fields_/)) {
      key = field_id.substring(cust_field_chars, field_id.length);
      filter_field = 'filter-' + key;
      filter_name = key.replace('_', ' ');
    } else {
      key = field_id.substring(non_tag_chars, field_id.length);
      filter_field = 'filter-' + key;
      filter_name = key.replace(/_/g, ' ');
      if ($(this).is('.datepicker')) {
        $("#" + filter_field).remove();
      }
      if ($(this).is('select')) {
        $("#" + filter_field).remove();
        field_value = $("#" + field_id + " option:selected").text().replace(/-/g, ' ');
      }
    }
    $('#create-member-group-panel').removeClass('hide');
    element_string = "<span id=\"" + filter_field + "\" class=\"label label-default criteria\">" + (filter_name.toUpperCase()) + ": <em>" + field_value + "</em><a href=\"javascript:void(0);\" onClick=\"clearSearchField('" + key + "')\"><i class=\"material-icons\">highlight_off</i></a></span>";
    if (add_filter_field) {
      return $('#segment-criteria').append(element_string);
    }
  }
};

$(function() {
  $(document).on('click', '#export_btn', filter_member_export);

  $(document).on('change', '#export_stats', function() {
    return alert_stats_export(this);
  });

  $(document).on('change', '#export_engagements', function() {
    return alert_stats_export(this);
  });

  $(document).on('click', '#save_as_segment', function() {
    return save_as_segment(this);
  });

  $('.action-link').tooltip();

  $('input.date_picker').datetimepicker({
    format: 'MM/DD/YYYY'
  });

  $('input.date_picker').parents('form').on('submit', function() {
    var altFormat, el;
    el = $(this).find('input.date_picker');
    altFormat = moment(el.val()).format('YYYY/MM/DD');
    return el.val(altFormat);
  });

  $('#reset-btn').on('click', function() {
    document.getElementById('search_form').reset();
    return setTimeout(location.reload.bind(location), 0);
  });

  $('#subscribed-true-to-false').on('click', function() {
    $('#unsubscribe_select').show();
    $('#subscribed-false-to-true').show();
    $('#subscribed-true-to-false').hide();
    $('#subscription_subscribed').val('false');
    return $('#secret-hidden-field').hide();
  });

  $('#subscribed-false-to-true').on('click', function() {
    $('#unsubscribe_select').hide();
    $('#subscribed-true-to-false').show();
    $('#subscribed-false-to-true').hide();
    $('#subscription_unsubscribe_reason_id').val('');
    $('#subscription_subscribed').val('true');
    return $('#secret-hidden-field').show();
  });

  $(document).on("change", '.applied-tag-checkbox', function() {
    let data;
    if (!$(this).is(':checked')) {
      return $.ajax({
        url: '/clients/' + $('#url_client_id').val() + '/member_taggings/' + $(this).val(),
        type: 'DELETE',
        dataType: 'script'
      });
    }
  });

  $(document).on("change", '.available-tag-checkbox', function() {
    let data;
    if ($(this).is(':checked')) {
      data = {};
      data['member_tagging'] = {};
      data['member_tagging']['member_id'] = $('#url_member_id').val();
      data['member_tagging']['client_id'] = $('#url_client_id').val();
      data['member_tagging']['member_tag_id'] = $(this).val();
      return $.ajax({
        url: '/clients/' + data['member_tagging']['client_id'] + '/member_taggings',
        type: 'POST',
        data: data,
        dataType: 'script'
      });
    }
  });

  $('[data-toggle="tooltip"]').tooltip();

  $('.add-custom-field').on('change', function() {
    var data, selected_field_id;
    selected_field_id = $(this).val();
    data = {};
    $('#search_form').serializeArray().forEach(function(input) {
      return data[input.name] = input.value;
    });
    if (selected_field_id !== '') {
      return $.ajax({
        url: '/clients/' + data['member_search_form[client_id]'] + '/custom_fields/' + selected_field_id,
        type: 'GET',
        data: {},
        dataType: 'script',
        success: function() {
          $("#custom_fields").find("option[value='" + selected_field_id + "']").attr('disabled', 'disabled');
          return $("#custom_fields").find("option[value='']").attr('selected', true);
        }
      });
    }
  });

  $(document).on('change', '.operators', function() {
    var parent_element;
    parent_element = $(this).parent().parent().parent();
    if ($(this).val() === 'bw') {
      return parent_element.find('.second-input').removeAttr('disabled');
    } else {
      return parent_element.find('.second-input').val('').attr('disabled', true);
    }
  });

  $('#search_form').on('change', 'input, select', load_tags);

  $('#search_form').find('.datepicker').on('dp.change', load_tags);

  $(document).on('focus', '.dynamic-datepicker', function() {
    return $(this).datetimepicker({
      format: 'MM/DD/YYYY'
    });
  });
});
