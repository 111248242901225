import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    identifier: String
  }

  toggle() {
    let elem = document.getElementById(this.identifierValue)
    elem.classList.toggle("hidden")
  }
}