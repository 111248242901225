let save_newsletter_articles;

$(function() {
    if ($('#article_status').val() === 'Published') {
        $('#scheduler_schedule_now').removeAttr('disabled');
    } else {
        $('#scheduler_schedule_now').attr('disabled', true);
    }
    $('.scheduling_options').toggle(false);
    $("#article_expires_at").datetimepicker({
        minDate: moment().local().add(1, 'd'),
        useCurrent: false,
        format: "YYYY-MM-DD",
        toolbarPlacement: 'top',
        showClear: true
    });
    $('#scheduler_schedule_now').click(function() {
        return $('.scheduling_options').toggle($('#schedule_now').prop('checked'));
    });
    $('#article_status').change(function() {
        if ($('#article_status').val() === 'Published') {
            return $('#scheduler_schedule_now').removeAttr('disabled');
        } else {
            return $('#scheduler_schedule_now').attr('disabled', true);
        }
    });
    $('#schedule_button').click(function() {
        return $('#schedule_modal').modal('show');
    });
    $('.article-select').change(function(e) {
        var content_type_id, fetch_url, request;
        fetch_url = $(this).data('fetch-url');
        if ($(this).val() !== '') {
            content_type_id = $(this).val();
        } else {
            content_type_id = 0;
        }
        return request = $.ajax({
            url: fetch_url,
            method: 'GET',
            data: {
                content_type_id: content_type_id,
                client_id: $(this).data('client-id'),
                article_position: $(this).data('article-position'),
                step: $(this).data('step')
            },
            dataType: 'script',
            cache: false
        });
    });
    $('#newsletter-article-wizard.wizard').on('finished', save_newsletter_articles);
    return $('#save-newsletter-articles').click(save_newsletter_articles);

    $('.icon-tooltip').tooltip();

    $(document).on('change', '#article_scheduler_launch_period', function() {
        var industry_id, launch_period, position_select;
        industry_id = $('#article_scheduler_industry_id').val();
        launch_period = $('#article_scheduler_launch_period').val();
        position_select = $('#article_scheduler_position');
        position_select.closest('div').removeClass('is-empty');
        if (launch_period) {
            return $.post("/article_schedulers/get_available_positions/" + industry_id + "/" + launch_period, function(data) {
                position_select.prop('disabled', false);
                if (data) {
                    return position_select.html(data);
                } else {
                    position_select.closest('div').addClass('is-empty');
                    return position_select.empty();
                }
            });
        } else {
            position_select.closest('div').addClass('is-empty');
            return position_select.empty();
        }
    });

    $(document).on('click', '.article-inline-close', function(e) {
        e.preventDefault();
        return $('.article-inline').remove();
    });
});

save_newsletter_articles = function(e) {
    let current_ids;
    current_ids = '';
    $.each($('#selected-newsletter-ids li'), function(key, value) {
        if (current_ids === "") {
            return current_ids = $(this).data('newsletter-id');
        } else {
            return current_ids = current_ids + "," + $(this).data('newsletter-id');
        }
    });
    $('#newsletter_ids').val(current_ids);
    return $('#newsletter-articles-form').submit();
};
