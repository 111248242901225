import consumer from "./consumer"

document.addEventListener('DOMContentLoaded', function() {
  let archive_section = document.getElementById('newsletter-readers')
  if (archive_section) {
    consumer.subscriptions.create({ channel: "OnlineMembersChannel", member: archive_section.dataset.memberId }, {
      connected() {
        // Do something here?
        console.log('Connected to OnlineMembersChannel')
      },

      disconnected() {
        // Do something here?
      },

      received(data) {
        console.log('Data received')
      }
    })
  }
})
