let show_launch_details;

$(document).ready(function() {
    $('.launch-details-link').click(show_launch_details);
    $('#datepicker').datetimepicker({
        format: 'YYYY/MM/DD'
    });
    $('#launch-date-search-button').click(function(e) {
        let launch_date;
        launch_date = $('#launch_date').val();
        return window.location = '/launches?launch_date=' + launch_date;
    });
});

$('.launch-count').each(function() {
    if ($(this).text() <= 20) {
        $(this).addClass('success');
    }
    if ($(this).text() > 20 && $(this).text() < 40) {
        $(this).addClass('warning');
    }
    if ($(this).text() > 39) {
        return $(this).addClass('danger');
    }
});

show_launch_details = function() {
    let next_elem;
    next_elem = $(this).parent().next();
    return next_elem.toggle('950');
};
