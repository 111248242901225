function addBannerAssignmentsChaining() {
    $('.banner_assignment').each((i, ia) => chainAssignment($(ia)))
    $('.assignments-container').on('cocoon:after-insert', (ev, insertedItem) => {
        chainAssignment($(insertedItem))
    })

    function chainAssignment (cont) {
        cont.find('[name$="[banner_type_id]"]').chained(cont.find('[name$="assignable_id]"]'))
        cont.find('[name$="[banner_category_id]"]').chained(cont.find('[name$="banner_type_id]"]'))
    }
}
$(document).ready(function() {
    addBannerAssignmentsChaining()
})
