$('.panel').tooltip();

$('#launch_month').chained('#launch_year');

$('#launch_period').chained('#launch_year,#launch_month');

$('#launch_quarter').change(function() {
    if ($('#launch_quarter').val() > 0) {
        $('.launch_period_group').val('');
        return $('.launch_period_group').attr('disabled', 'disabled');
    } else {
        if ($('#launch_year').val() > 0) {
            return $('#launch_month').removeAttr('disabled');
        }
    }
});

$('#launch_year').change(function() {
    return $('#launch_quarter').val('');
});
