import { Controller } from "stimulus"

export default class extends Controller {

  load(event) {
    const csrf_token = document.querySelector("[name='csrf-token']").content
    let article_text = document.getElementById("article_body").value;
    let current_keywords = document.getElementById('article_old_keywords').value;
    let post_data = JSON.stringify({ "article_body": article_text, "article_old_keywords": current_keywords })
    let suggested_keywords_div = document.getElementById("suggested_article_keywords");
    fetch(
      "/articles/keyword_generators",
      {
        method: "POST",
        headers: {
          'X-CSRF-Token': csrf_token,
          'Accept': 'application/html',
          'Content-Type': 'application/json'
        },
        body: post_data
      }
    ).then(
      response => response.text()).then(html => suggested_keywords_div.innerHTML = html
    );
    // this is some hard-coded crap because, with the 6.x line, it's no longer going to be needed/used/exist
    let loaderElem = document.getElementById('loader')
    loaderElem.classList.remove('show')
    loaderElem.classList.add('hidden')
  }
}
