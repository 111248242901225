function validUrl(url) {
  let url_regexp = /^(https?|chrome):\/\/[^\s$.?#].[^\s]*$/gm;
  let validated_url = url_regexp.test(url);
  if (validated_url) {
    return true;
  } else {
    alert('You must provide a valid, full URL; including the protocl (e.g., https://www.google.com)');
    return false;
  }
}

$(function () {
  $(document).on('click', '.addon_breakdown', function (e) {
    let _url;
    e.preventDefault();
    _url = $(this).attr('href');
    return $.get(_url, (function (data) {
      $('#' + data.table.wrapper_id).empty();
      $('#' + data.table.wrapper_id + '-wrapper').toggleClass('hidden');
      new_area_chart(data.table.wrapper_id, data.table.json_data, data.table.xkey, data.table.ykeys, data.table.labels);
    }), 'json');
  });

  $(document).on('click', '.addon-breakdown-toggler', function (e) {
    let element;
    e.preventDefault();
    element = $(this).attr('data-target');
    return $('#' + element).toggleClass('hidden');
  });

  $('#neighborhood-navigator-modal').hide();

  $(".slow-auto-close-alert").fadeTo(15000, 500).slideUp(500, function () {
    $(".slow-auto-close-alert").alert("close");
  });

  $('.tool-tipper').tooltip();

  $(document).on('submit', '.long-submit-form', function (e) {
    let elem = $('.long-submit-button');
    elem.attr('disabled', true);
    if (elem.is(':button')) {
      elem.html("Processing...");
    } else {
      elem.prop('value', "Processing...");
    }
  });

  $('.tooltip-link').tooltip({
    placement: 'right'
  });

  $(document).on('click', '#client_alerts input[type=checkbox], .remote_alert_submit', function (e) {
    $(this).closest("form").submit();
    if ($(this).data('name') === 'is_real_time' && $(this).prop('checked') === true) {
      $(this).closest('form').find('[type=checkbox]').prop('checked', true);
    }
    if ($(this).data('name') === 'is_real_time' && $(this).prop('checked') === false) {
      return $(this).closest('form').find('[type=checkbox]').prop('checked', false);
    }
  });

  // Banner link to validations
  $(document).on('submit', '#default-banner-form', function (ev) {
    let link_url = document.getElementById('default_banner_link_to').value
    let validated = validUrl(link_url)
    if (validated) {
      return true;
    } else {
      ev.preventDefault();
      return false;
    }
  });

  $('.remote_submit').click(function (e) {
    return $(this).closest("form").submit();
  });

  $(document.body).on("click", ".upcoming-newsletters-list li a", function (event) {
    var $target;
    $target = $(event.currentTarget);
    return $target.closest(".dropdown").find("[data-bind=\"label\"]").text($target.text()).end().children(".dropdown-toggle").dropdown("toggle");
  });

  $('.context_help').popover({
    html: true
  });

  $('#mainnav-profile-avatar').popover({
    html: true
  });

  $('.pre-flight-check').blur(function(e) {
    $.ajax({
      url: '/email_processors',
      type: 'POST',
      data: { email: $(this).val(), client_id: $('.client-identifier').val() },
      dataType: 'SCRIPT'
      });
  });

  CKEDITOR.on("instanceReady", function (event) {
    event.editor.on("beforeCommandExec", function (event) {
      // Show the paste dialog for the paste buttons and right-click paste
      if (event.data.name === "paste") {
        event.editor._.forcePasteDialog = true;
      }
      // Don't show the paste dialog for Ctrl+Shift+V
      if (event.data.name === "pastetext" && event.data.commandData.from === "keystrokeHandler") {
        event.cancel();
      }
    })
  });
});
