var validateEmail, validateURL;

$('#other_recipient_email').change(function(e) {
    if (!validateEmail($('#other_recipient_email').val())) {
        $('#send_single_form').parent().append('<div class="alert alert-error" id="invalid-email-warning"><button type="button" class="close" data-dismiss="alert">&times;</button><strong>Warning!</strong> An email is required if you are not sending to yourself.</div>');
        return $('#send_single_submit_button').prop('disabled', true);
    } else {
        $('#invalid-email-warning').remove();
        return $('#send_single_submit_button').prop('disabled', false);
    }
});

$('#send_single_form').submit(function(e) {
    var recipient;
    $('#send_single_submit_button').prop('disabled', true);
    return recipient = $("input[type='radio'][name='recipient']:checked").val();
});

$('.banner-library-select').change(function(e) {
    var fetch_url;
    fetch_url = $(this).data('fetch-url') + '/' + $(this).val();
    return $.ajax({
        url: fetch_url,
        method: 'GET',
        data: {
            banner_library_id: $(this).val(),
            step: $(this).data('step'),
            banner_location: $(this).data('banner-location')
        },
        dataType: 'script',
        cache: false
    });
});

$('#banner_banner_library').change(function(e) {
    var banner_library_id, form, params, request, submit_to;
    form = $('#default-banners-form');
    submit_to = form.attr('action') + '/' + $(this).val() + '.json';
    banner_library_id = Number($(this).val());
    params = {
        search_banners: true,
        banner_library_id: banner_library_id,
        dimensions: $('#banner_dimensions').val(),
        location: $('#banner_location').val()
    };
    if (typeof banner_library_id === 'number') {
        request = $.ajax({
            url: submit_to,
            type: 'GET',
            data: params,
            dataType: 'json'
        });
        return request.done(function(data) {
            var banner_div_elem, banner_heading_elem, banner_id, banner_img_elem, banner_input_elem, banner_modal_link_elem, banner_title, file_name, key, results;
            $('#banner-previews').html('');
            results = [];
            for (key in data) {
                banner_id = data[key].id;
                file_name = data[key].image_file_name;
                banner_title = data[key].title;
                banner_heading_elem = $('<h4>' + banner_title + '</h4>');
                banner_input_elem = $('<div class="banner-preview-inner"><label class="banner-preview-label">Set as default&nbsp;<input type="radio" name="banner[id]" id="banner_id" value="' + banner_id + '" title="' + banner_title + '" /></label></div>');
                banner_modal_link_elem = $('<a href="#banner-modal" role="button" class="btn" data-toggle="modal">View Larger</a>');
                banner_img_elem = $('<img src="http://24008b154b1ac6abb490-462ad1c489ff879e755d020225ee995f.r79.cf1.rackcdn.com/images/banners/' + banner_id + '/original/' + file_name + '" class="img-polaroid" />');
                banner_div_elem = $('<div class="banner-preview"></div>');
                banner_div_elem.append(banner_heading_elem).append(banner_img_elem).append(banner_input_elem);
                results.push($('#banner-previews').append(banner_div_elem));
            }
            return results;
        });
    }
});

$('#default-banner-wizard').change(function(e) {
    var index, li;
    li = $('.steps li.active');
    index = $('.steps li').index(li);
    switch (index) {
        case 0:
            if ($('#banner_dimensions').val() === '' || $('#banner_location').val() === '') {
                e.preventDefault();
                return $('#default-banner-wizard').prepend('<div class="alert alert-danger"><button type="button" class="close" data-dismiss="alert">&times;</button><strong>Uh-oh!</strong> You need to select both a dimension and location for your banner.</div>');
            }
            break;
        case 1:
            if ($('#banner_banner_library').val() === '') {
                e.preventDefault();
                return $('#default-banner-wizard').prepend('<div class="alert alert-danger"><button type="button" class="close" data-dismiss="alert">&times;</button><strong>Uh-oh!</strong> You need to select a banner library.</div>');
            }
            break;
        case 2:
            if (!$('input[name="banner[id]"]:checked').val()) {
                e.preventDefault();
                $('#default-banner-wizard').prepend('<div class="alert alert-danger"><button type="button" class="close" data-dismiss="alert">&times;</button><strong>Uh-oh!</strong> You need to select a banner. You may use the "Library" link to go back and select a new library if there are no banners available.</div>');
            }
            return $('#banner-selections').empty().append('<li>Dimensions: ' + $('#banner_dimensions').val() + '</li>').append('<li>Location: ' + $('#banner_location').val() + '</li>').append('<li>Library: ' + $('#banner_banner_library option[value="' + $('#banner_banner_library').val() + '"]').text() + '</li>').append('<li>Banner: ' + $('#banner_id').attr('title') + '</li>');
        case 3:
            $('#banner-selections').empty().append('<li>Dimensions: ' + $('#banner_dimensions').val() + '</li>').append('<li>Location: ' + $('#banner_location').val() + '</li>').append('<li>Library: ' + $('#banner_banner_library option[value="' + $('#banner_banner_library').val() + '"]').text() + '</li>').append('<li>Banner: ' + $('input[name="banner[id]"]:checked').attr('title') + '</li>');
            if ($('#banner_link').val() === '') {
                return e.preventDefault();
            }
    }
});

$('.newsletter-banners-toggle').change(function(e) {
    var state;
    state = $(this).prop('checked');
    if (state) {
        return $('#calendar, #affix-pillbox').hide();
    } else {
        return $('#calendar, #affix-pillbox').show();
    }
});

$('#schedule-articles-form').submit(function(e) {
    var article_id, newsletter_ids;
    article_id = $('[name="newsletter_article[article_id]"]:checked').val();
    newsletter_ids = $('[name="newsletter_article[newsletter_ids][]"]:checked').val();
    if (typeof article_id === 'undefined' || typeof newsletter_ids === 'undefined') {
        $('.content .container').prepend('<div class="alert alert-danger"><button type="button" class="close" data-dismiss="alert">&times;</button><strong>Uh-oh!</strong> The following items are required: an article and a newsletter.</div>');
        return false;
    } else {
        return true;
    }
});

$('#schedule-banners-form').submit(function(e) {
    var banner_id, banner_link, banner_position_id, newsletter_ids;
    banner_position_id = $('[name="newsletter_banner[position]"]:checked').val();
    banner_id = $('[name="newsletter_banner[banner_id]"]:checked').val();
    newsletter_ids = $('[name="newsletter_banner[newsletter_ids][]"]:checked').val();
    banner_link = $('[name="newsletter_banner[link]"]').val();
    if ((typeof banner_position_id === 'undefined') || (typeof banner_id === 'undefined' || typeof newsletter_ids === 'undefined' || (banner_link === '' || !validateURL(banner_link)))) {
        $('.content .container').prepend('<div class="alert alert-danger"><button type="button" class="close" data-dismiss="alert">&times;</button><strong>Uh-oh!</strong> The following items are required: a banner, the banner position, a link, and a newsletter.</div>');
        return false;
    } else {
        return true;
    }
});

$(document).on('click', '.relaunch-btn', function() {
    $(this).attr('disabled', true);
    $('.content .container').prepend('<div class="alert alert-danger"><button type="button" class="close" data-dismiss="alert">&times;</button><strong>Relaunch Underway!</strong> The selected newsletter has been relaunched to members who did not receive it previously.</div>');
    return window.scrollTo(0, 0);
});

$(document).on('click', '.available-articles', function(e) {
    e.preventDefault();
    return $('tbody#sortable').append('<tr id="articles_' + $(this).data('value') + '"><td class="ui-state-default"><span class="ui-icon ui-icon-arrowthick-2-n-s"></span>' + $(this).data('title') + '</td><td align="center"><a href="#" class="btn btn-xs btn-danger remove-article-btn"><i class="fa fa-trash-o"></i></a></td></tr>');
});

$(document).on('click', '#article-ordering-btn', function(e) {
    var articles, get_url;
    e.preventDefault();
    articles = $('tbody#sortable').sortable('serialize');
    if (articles.length === 0) {
        articles = 'clear_articles=true';
    }
    get_url = $(this).attr('href') + "?" + articles;
    return $.ajax({
        url: get_url,
        method: 'GET',
        dataType: 'script',
        cache: false
    });
});

$(document).on('click', '.remove-article-btn', function(e) {
    e.preventDefault();
    return $(this).parent().parent().remove();
});


/*
Validate email function with regular expression

If email isn't valid then return false

@param email
@return Boolean
 */

validateEmail = function(email) {
    var emailReg, valid;
    emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    valid = emailReg.test(email);
    if (!valid) {
        return false;
    } else {
        return true;
    }
};

validateURL = function(url) {
    var urlPattern, valid;
    urlPattern = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/;
    valid = urlPattern.test(url);
    if (!valid) {
        return false;
    } else {
        return true;
    }
};
