$(function() {
    var clientNames, curb_call_id;
    $('#curb-call-client-form').submit(function(e) {
        var action_url, client_id, curb_call_id, post_data, raw_client_id;
        e.preventDefault();
        curb_call_id = $('#curb_call_client_curb_call_id').val();
        raw_client_id = $('#curb_call_client_client_id').val();
        client_id = parseInt(raw_client_id.substr(raw_client_id.indexOf('^') + 1), 10);
        if (client_id) {
            action_url = '/curb_calls/' + curb_call_id + '/curb_call_clients/';
            post_data = {
                curb_call_client: {
                    curb_call_id: $('#curb_call_client_curb_call_id').val(),
                    curb_call_agent_id: $('#curb_call_client_curb_call_agent_id').val(),
                    latitude: $('#curb_call_client_latitude').val(),
                    longitude: $('#curb_call_client_longitude').val(),
                    physical_location: $('#curb_call_client_physical_location').val(),
                    client_id: client_id
                }
            };
            return $.post(action_url, post_data, (function(data) {
                $('#curb-call-client-form')[0].reset();
                $('#curb-call-client-modal').modal('hide');
                window.toastr_alert('success', 'Success!', 'Client added to CurbCall.');
                return window.location.reload();
            }), 'json').fail(function(data) {
                return window.toastr_alert('error', 'Error!', 'Unable to add the client to CurbCall. Reload the page and try again.');
            });
        } else {
            return window.toastr_alert('error', 'Error!', 'You must select a client.');
        }
    });
    curb_call_id = $('#curb_call_client_curb_call_id').val();
    clientNames = new Bloodhound({
        datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
        queryTokenizer: Bloodhound.tokenizers.whitespace,
        remote: {
            url: '/clients/search.json?search_type=curb_call&curb_call_id=' + curb_call_id + '&filter=%QUERY',
            wildcard: '%QUERY'
        }
    });
    return $('.typeahead').typeahead({
        hint: true,
        highlight: true,
        minLength: 3
    }, {
        name: 'client-names',
        source: clientNames,
        limit: 24
    });
});
