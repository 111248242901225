import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["otherField", "baseField"]

  setOtherField() {
    const otherFieldOptions = ['other', 'Other', '']
    let fieldElement = this.otherFieldTarget

    if(otherFieldOptions.includes(this.baseFieldTarget.value)) {
      fieldElement.removeAttribute('disabled')
    } else {
      fieldElement.value = ""
      fieldElement.setAttribute("disabled", "true")
    }
  }
}