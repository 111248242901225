window.toastr_alert = function(alertType, title, bodyText, timeOut) {
    let options;
    if (timeOut == null) {
        timeOut = 5;
    }
    options = {
        "closeButton": true,
        "debug": false,
        "progressBar": true,
        "positionClass": "toast-top-right",
        "onclick": null,
        "showDuration": "400",
        "hideDuration": "1000",
        "timeOut": (timeOut * 1000).toString(),
        "extendedTimeOut": "1000",
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut",
        "escapeHTML": "true",
        "preventDuplicates": "true"
    };
    switch (alertType.toLowerCase()) {
        case "error":
            return toastr.error(bodyText, title, options);
        case "info":
            return toastr.info(bodyText, title, options);
        case "success":
            return toastr.success(bodyText, title, options);
        default:
            return toastr.warning(bodyText, title, options);
    }
};
