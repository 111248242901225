$(document).on('change', '.task_complete', function(e) {
    var completed_date, isChecked;
    isChecked = $(this).prop('checked');
    if (isChecked === true) {
        completed_date = moment().format();
    } else {
        completed_date = "";
    }
    return $.ajax({
        url: $(this).data('url'),
        data: {
            task: {
                completed: isChecked,
                completed_at: completed_date
            }
        },
        dataType: 'script',
        method: "PUT"
    });
});
